import { withRouter } from 'react-router';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import Image from 'SourceComponent/Image';
import { ImageRatio } from 'SourceComponent/Image/Image.type';
import {
    ProductGalleryComponent as SourceProductGallery,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import ProductGalleryBaseImage from 'SourceComponent/ProductGalleryBaseImage';
import Slider from 'SourceComponent/Slider';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

/** @namespace Theme/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    renderImage(mediaData, index) {
        const {
            isZoomEnabled,
            handleZoomChange,
            disableZoom,
            isMobile,
            isImageZoomPopupActive,
            showLoader,
            productName,
        } = this.props;
        const { scrollEnabled } = this.state;

        if (!isMobile) {
            const {
                base: { url: baseSrc } = {},
                large: { url: largeSrc } = {},
                label,
            } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive ? largeSrc || baseSrc : baseSrc;

            return (
                <Image
                  key={ index }
                  src={ src }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src },
                  } }
                  alt={ label || productName }
                  isPlaceholder={ !src }
                  style={ style }
                  showIsLoading={ showLoader }
                  // eslint-disable-next-line react/jsx-no-bind
                  onImageLoad={ () => {
                      setLoadedFlag();
                      window.isPrefetchValueUsed = false;
                  } }
                  loading="eager"
                />
            );
        }

        return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheel={ this.onWheel }
              wheel={ { limitsOnWheel: true, disabled: !scrollEnabled } }
            //   doubleClick={ { mode: 'reset' } }
              pan={ {
                  disabled: !isZoomEnabled,
                  velocity: false,
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1,
              } }
            >
                { /* @ts-ignore */ }
                { (params) => {
                    const {
                        scale,
                        previousScale,
                        resetTransform,
                        setTransform,
                    } = params;

                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isZoomEnabled }
                        />
                    );
                } }
            </TransformWrapper>
        );
    }

    renderPlaceholderImage() {
        const { activeImage } = this.props;

        const {
            location: {
                state: {
                    product: { small_image: { url = '' } = {} } = {},
                } = {},
            } = {},
        } = history ?? {};

        return (
            <Image
              src={ url }
              ratio={ ImageRatio.IMG_CUSTOM }
              loading="eager"
              mix={ {
                  block: 'ProductGallery',
                  elem: 'SliderImage',
                  mods: { isHidden: activeImage !== undefined },
              } }
            />
        );
    }

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCrumbs={ isMobile && isMoreThanOnePhoto }
                  showArrows={ false }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ !isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
