import { Suspense } from 'react';

import Loader from 'Component/Loader';
import ProductLinks from 'Component/ProductLinks';
import ProductNotes from 'Component/ProductNotes';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import {
    ProductPageComponent as SourceProductPage,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';

/** @namespace Theme/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPage {
    tabMap = {
        [ProductPageTabs.INFORMATION]: {
            name: __('Description'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key),
        },
        PRODUCT_NOTES: {
            name: __('Notes'),
            shouldTabRender: () => {
                const { notes } = this.props;

                return !notes;
            },
            render: (key) => this.renderProductNotesTab(key),
        },
    };

    renderProductNotesTab(key) {
        const {
            dataSource,
            parameters,
            areDetailsLoaded,
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductNotes
                  product={ { ...dataSource, parameters } }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                { this.renderProductTabs() }
                <ProductLinks
                  linkType={ LinkedProductType.RELATED }
                  title={ __('Related Products') }
                  areDetailsLoaded={ areDetailsLoaded }
                />
                <ProductLinks
                  linkType={ LinkedProductType.UPSELL }
                  title={ __('You might also like') }
                  areDetailsLoaded={ areDetailsLoaded }
                />
            </>
        );
    }

    renderProductDesktopMainData() {
        return null;
    }
}

export default ProductPageComponent;
