import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

/** @namespace Theme/Component/ProductNotes/Component */
export class ProductNotesComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        areDetailsLoaded: PropTypes.bool.isRequired,
    };

    renderNotes() {
        const { product: { notes } } = this.props;

        if (!notes) {
            return (
                <TextPlaceholder length="long" />
            );
        }

        const cleanNotes = notes.replace(/<\/?[^>]+(>|$)/g, '');

        return (
            <div block="ProductInformation" elem="Notes">
                <meta itemProp="notes" content={ cleanNotes } />
                <Html content={ notes } />
            </div>
        );
    }

    renderContent() {
        const { areDetailsLoaded, product: { notes } } = this.props;
        const heading = areDetailsLoaded ? __('Notes') : '';

        if (!notes) {
            return null;
        }

        return (
            <ExpandableContent
              isContentExpanded={ false }
                // show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'ProductInformation', elem: 'Content' } }
            >
                { this.renderNotes() }
            </ExpandableContent>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    isHTMLWhiteSpaceOrUndefined(htmlString) {
        if (!htmlString || htmlString.trim() === '') {
            return true;
        }

        // creates a DOM object from string
        const parser = new DOMParser();
        const document = parser.parseFromString(htmlString.trim(), 'text/html');

        // handles the case of plain text
        if (document.body.children.length === 0) {
            return false;
        }

        // check if at least one HTML element has content
        const elementsWithContent = Array.from(document.body.children).filter((element) => element.innerText !== '');

        return elementsWithContent.length === 0;
    }

    render() {
        const {
            areDetailsLoaded,
            product: {
                notes,
            },
        } = this.props;

        if (this.isHTMLWhiteSpaceOrUndefined(notes) && areDetailsLoaded) {
            return null;
        }

        if (!isSignedIn()) {
            return null;
        }

        return (
            <section
              aria-label="Product notes"
              mix={ { block: 'ProductInformation', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </section>
        );
    }
}

export default ProductNotesComponent;
